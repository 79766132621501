<template>
  <vs-dialog v-model="active" blur prevent-close not-close>
    <template #header>
      <div>
        <h4 class="not-margin">Bienvenido a <b>TraumaReports</b></h4>
      </div>
    </template>
    <!-- <div> -->
    <!-- <vs-avatar> -->
    <!-- <img :src="require('@/assets/clinikform.png')" /> -->
    <!-- </vs-avatar> -->
    <!-- </div> -->
    <div class="con-form">
      <vs-input
        v-model="email"
        state="primary"
        label-placeholder="Email"
        class="inputLogin"
      >
        <template #icon>
          @
        </template>
      </vs-input>
      <vs-input
        type="password"
        v-model="password"
        state="primary"
        label-placeholder="Password"
        class="inputLogin"
        @keydown.enter="onLogin"
      >
        <template #icon>
          <i class="fas fa-lock"></i>
        </template>
      </vs-input>
      <div class="flex">
        <!-- <vs-checkbox v-model="remember">Remember me</vs-checkbox> -->
        <a href="#">Has olvidado la contraseña?</a>
      </div>
    </div>

    <template #footer>
      <div class="footer-dialog">
        <vs-button @click="onLogin" block>
          Entrar
        </vs-button>

        <div class="new">
          No tienes cuenta? <a href="/register">Crear una nueva cuenta</a>
        </div>
      </div>

      <!-- <small>
        Powered by
        <a
          href="http://www.clinikform.com"
          target="_blank"
          rel="noopener noreferrer"
          >ClinikForm</a
        >
      </small> -->
      <!-- <img width="30px" :src="require('@/assets/clinikform.png')" /> -->
    </template>
  </vs-dialog>
</template>

<script>
import { authMix } from "@/mixins/mixins.js";

export default {
  name: "LoginPage",
  mixins: [authMix],
  data() {
    return {
      active: true,
      email: "",
      password: "",
    };
  },
  methods: {
    async onLogin() {
      const user = await this.firebaseLogin(this.email, this.password);
      if (user) {
        this.$store.commit("setUserInfo", user);
        this.$router.push("/").catch((error) => {
          console.info(error.message);
          this.$vs.notification({
            color: "danger",
            position: "top",
            title: "Error al logearse",
            text:
              "El usuario o contraseña es incorrecto, si el problema persiste, por favor ponte en contacto con el administrador.",
          });
        });
      } else {
        this.$vs.notification({
          color: "danger",
          position: "top",
          title: "Error al logearse",
          text:
            "El usuario o contraseña es incorrecto, si el problema persiste, por favor ponte en contacto con el administrador.",
        });
        //TO-DO: Poner un mensaje de error al errar el login
      }
    },
  },
};
</script>

<style scoped>
.con-form > div {
  margin-bottom: 1.5rem;
}
</style>

<style>
.inputLogin > div > .vs-input {
  max-width: none;
  width: 100%;
}
</style>
